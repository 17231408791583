import React, { useEffect, useState, useRef } from 'react';
import { Layout, SEO } from '@src/components';
import { useSelector } from 'react-redux';
import { login } from '@src/utils/mmc-api/auth';
import { Button } from 'components/UI/Button';
import DatePicker from 'react-date-picker';
import * as api from '@src/utils/mmc-api/api';

import { fetchUser } from '../../store/user';

import { useDebounce } from '../../utils/debounce';
import { Table } from '../../components/Table';
import { getAllPurchases } from '../../utils/mmc-api/api';

import { useDispatch } from 'react-redux';

function useAsyncRef(ref) {
  const value = useRef(ref);
  const [, forceRender] = useState(false);

  function updateValue(newState) {
    value.current = newState;
    forceRender((s) => !s);
  }
  return [value, updateValue];
}
const times = [
  { id: 9, name: '09:00' },
  { id: 10, name: '10:00' },
  { id: 11, name: '11:00' },
  { id: 12, name: '12:00' },
  { id: 13, name: '13:00' },
  { id: 14, name: '14:00' },
  { id: 15, name: '15:00' },
  { id: 16, name: '16:00' },
  { id: 17, name: '17:00' },
  { id: 18, name: '18:00' },
  { id: 19, name: '19:00' },
  { id: 20, name: '20:00' },
  { id: 21, name: '21:00' },
  { id: 22, name: '22:00' },
  { id: 23, name: '23:00' },
];

const AdminPage = () => {
  const user = useSelector((state) => state.user.data);

  const [products, setProducts] = React.useState([])
  const [totalResults, setTotalResults] = React.useState(0);
  const [emailSearch, setEmailSearch] = React.useState('');

  const dispatch = useDispatch();

  const [pageNum, setPageNum] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(20);

  const getPurchases = () => {
    getAllPurchases(pageSize, pageNum, emailSearch).then(response => {
      if (!response) return;
      const { data, totalResults } = response;
      setProducts(data);
      setTotalResults(totalResults);
    });
  };

  React.useEffect(() => {
    (async function () {
        if (!user) await dispatch(fetchUser());
        if (user.role !== 'Admin') return window.location = '/';
        getPurchases();
    })()
  }, []);

  React.useEffect(getPurchases, [pageNum, pageSize]);

  const debouncedRequest = useDebounce(() => {
    getPurchases(pageSize, pageNum, emailSearch);
  });

  const handlePageSizeChange = value => {
    setPageSize(value);
    setPageNum(1);
  };

  const onEmailChange = (e) => {
    const value = e.target.value;
    setEmailSearch(value);

    debouncedRequest();
  };


  const headers = [
    {
      label: 'Name',
      value: ['fullname', 'username']
    },
    {
      label: 'Email',
      value: 'email'
    },
    {
      label: 'Product name',
      value: 'name'
    },
    {
      label: 'Module',
      value: 'module'
    },
    {
      label: 'Category',
      value: 'category'
    },
    {
      label: 'Price',
      value: 'price'
    }
  ];

  const pageSizes = [10, 20, 50];

  return (
    <Layout>
      <SEO title="Admin!" />
      <div className="container">
        <h2>Purchased Documents</h2>
        <div className="srow x-space-between">
          <div className="scolumn 4">
            <label>Email Address</label>
            <input className="input" id="email" type="text" onChange={onEmailChange} value={emailSearch} />
          </div>
          <div className="scolumn 1">
            <label>Page size</label>
            <select
              className="dropdown"
              value={pageSize}
              onChange={e => handlePageSizeChange(parseInt(e.target.value))}
            >
              {pageSizes.map((size, i) => (<option key={i} value={size}>{size}</option>))}
            </select>
          </div>
        </div>
        <Table
          data={products}
          headers={headers}
          totalResults={totalResults}
          pageSize={pageSize}
          pageNum={pageNum}
          changePageNum={setPageNum}
        />
      </div>
    </Layout>
  );
};

const AdminBooking = () => {
  const user = useSelector((state) => state.user.data);
  const [dateIndex, setDateIndex] = useAsyncRef(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [stopDate, setStopDate] = useState();
  const [timeId, setTimeId] = useState([]);
  const [usersList, setUsersList] = useState([{ id: 0, name: 'None' }]);
  const [selectedUser, setSelectedUser] = useState({});
  const [modalSelectedUser, setModalSelectedUser] = useState({});
  const [repeat, setRepeat] = useAsyncRef('none');
  const [error, setError] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTime, setSelectedTime] = useState({});
  const [filteredTimes, setFilteredTimes] = useState(times);

  async function fetchUsers() {
    const users = await api.users();
    let userlist = [{ id: 0, name: 'None', email: '' }];
    let count = 0;
    users[0].map((user) => {
      count++;
      userlist.push({ id: count, name: user.username, email: user.email });
    });
    setUsersList(userlist);
  }

  const processDates = (date) => {
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    return y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)

  }
  const fetchBookings = async (startDate) => {
    const newDate = processDates(startDate) + 'T00:00:00.000Z';
    setBookings(await api.getAvailableDates(newDate));
  };

    useEffect(() => {
      fetchUsers();
      fetchBookings(new Date());
    }, []);

  useEffect(() => {
    const newDate = processDates(selectedDate);
    const filteredDates = bookings.filter(booking => booking.date === newDate)
    const filterTime = times.filter(time => parseInt(time.id) !== parseInt(filteredDates[0]?.timeslot));
    setFilteredTimes(filterTime);
  }, [selectedDate, bookings]);

  const dateArrayHandler = (startDate, index, offset = 1) => {
    const date = new Date(startDate);
    if (offset != 1) {
      index = Math.floor(index / offset);
    }
    var dateArray = [];
    var newOffset;
    for (var i = 0; i < index; i++) {
      if (i === 0) { newOffset = 0; } else { newOffset = offset; }
      dateArray.push(new Date(date.setDate(date.getDate() + newOffset)));
    }
    return dateArray;
  };

  const createBooking = async (data) => {
    const request = await api.addAvailableDate(data);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let diffDays = 0;
    if (stopDate) {
      const date1 = new Date(selectedDate);
      const date2 = new Date(stopDate);
      const diffTime = Math.abs(date2 - date1);
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    if (timeId.length > 0) {
      const start = [];
      start.push(
        ...dateArrayHandler(
          selectedDate,
          diffDays == 0 ? 1 : diffDays,
          repeat.current == 'week' ? 7 : 1,
        ),
      );
      const data = {
        dateArray: start,
        timeslot: timeId,
        user: selectedUser,
        repeat: repeat.current,
        stopDate: stopDate,
      };
      //Create Booking and reset form
      createBooking(data);
      setSelectedDate(new Date());
      setRepeat('none');
      setTimeId([]);
      setStopDate();
      setSelectedUser('');
      fetchBookings(new Date());
    } else {
      setError(true);
    }
  };

  const selectChangeHandler = (e) => {
    let times;
    const timeExists = timeId?.filter((item) => item === e.target.value);
    if (timeExists?.length > 0) {
      times = timeId.filter((item) => item !== e.target.value);
    } else {
      times = timeId;
      times.push(e.target.value);
    }
    setTimeId(times);
    if (times.length > 0) {
      setError(false);
    }

  };

  const userSelectChangeHandler = (e) => {

    const client = usersList.filter((user) => user.id === e.target.value);
    setSelectedUser(client[0]);
  };

  const repeatSelectChangeHandler = (e) => {
    const date = new Date(selectedDate);
    switch (e.target.value) {
      case 'day':
        setStopDate(new Date(date.setDate(date.getDate() + 1)));
        break;
      case 'week':
        setStopDate(new Date(date.setDate(date.getDate() + 7)));
        break;
      default:
    }
    setRepeat(e.target.value);
  };

  const [displayDates, setDisplayDates] = useState(dateArrayHandler(new Date(), 7));

  const dateIncreaseHandler = () => {
    setDateIndex(dateIndex.current + 1);
    const currentDate = new Date();
    const newDate = new Date(currentDate.setDate(currentDate.getDate() + dateIndex.current * 7));
    fetchBookings(newDate);
    setDisplayDates(dateArrayHandler(newDate, 7));
  };

  const dateDecreaseHandler = () => {
    if (dateIndex.current > 0) {
      setDateIndex(dateIndex.current - 1);
    }
    const currentDate = new Date();
    const newDate = new Date(currentDate.setDate(currentDate.getDate() + dateIndex.current * 7));
    fetchBookings(newDate);
    setDisplayDates(dateArrayHandler(newDate, 7));
  };

  const dateFilterHandler = (date) => {
    const newDate = processDates(date);
    const timeArray = [];
    bookings.map((booking) => {
      if (booking.date === newDate) {
        timeArray.push({
          id: booking.id,
          date: booking.date,
          time: booking.timeslot,
          user: booking.user,
        });
      }
    });
    return timeArray.sort(function (a, b) {
      return a - b;
    });
  };

  const deleteButtonHandler = async (id) => {
    const request = await api.removeAvailableDate(id);

    var filteredBookings = [];
    var exists = false;

    bookings.map((booking) => {
      if (booking.date === request.date) {
        if (booking.timeslot === request.timeslot) {
          exists = true;
        }
      }
      if (!exists) {
        filteredBookings.push(booking);
      }
      setBookings(filteredBookings);
      exists = false;
    });
    setShowModal(false);
  };

  const alertHandler = (time) => {
    setShowModal(true);
    setSelectedTime(time);
    setModalSelectedUser(time.user.id);
  };
  return (
    <>
      <div>
        admin page
        <form>
          <div className="inputlabel">Date</div>
          <DatePicker
            name="start"
            onChange={setSelectedDate}
            value={selectedDate}
            className="datePicker"
            required
          />
          <div className="inputlabel">Select Time Slots</div>
          <select className="select-css" value={timeId} multiple onChange={selectChangeHandler}>
            {filteredTimes.map((time) => (
              <option value={time.id} key={time.id}>
                {time.name}
              </option>
            ))}
          </select>
          {error && <span className="error">Please Select at least one timeslot</span>}
          <div className="inputlabel">Allocate to client</div>
          <select
            className="select-css"
            value={selectedUser?.user?.id}
            onChange={userSelectChangeHandler}
          >
            {usersList.map((user) => (
              <option value={user.id} key={user.id}>
                {user.name}
              </option>
            ))}
          </select>
          <div className="inputlabel">Repeat by</div>
          <select
            className="select-css"
            value={repeat.current}
            onChange={repeatSelectChangeHandler}
          >
            <option value="none">None</option>
            <option value="day">Repeat each day</option>
            <option value="week">Repeat each week</option>
          </select>

          <div className="inputlabel">Repeat until</div>
          <DatePicker
            name="until"
            onChange={setStopDate}
            value={stopDate ? stopDate : selectedDate}
            className="datePicker"
            required
          />

          <Button onClick={submitHandler} buttonColor="blue" buttonSize="btn--small">
            Submit
          </Button>
        </form>
        {!user ? (
          <a className="navigation-link" onClick={login}>
            Sign in
          </a>
        ) : (
          <div className="dateDisplayContainer">
            <div className="indexDateContainer">
              <a className="navigation-link" onClick={dateDecreaseHandler}>
                decrease date
              </a>
            </div>

            {displayDates.map((date) => (
              <div className="daysContainer">
                {date.toLocaleDateString()}
                {dateFilterHandler(date).map((time) => (
                  <div
                    onClick={() => {
                      alertHandler(time);
                    }}
                  >
                    {time.time}:00 to {time.time + 1}:00
                  </div>
                ))}
              </div>
            ))}

            <div className="indexDateContainer">
              <a className="navigation-link" onClick={dateIncreaseHandler}>
                Increase Date
              </a>
            </div>
          </div>
        )}
      </div>
      {
        showModal && (
          <div className="modalContainer">
            <div>Time Slot Id {selectedTime.id}</div>
            <div>Date: {selectedTime.date}</div>
            <div>Time :{selectedTime.time}</div>
            <div className="inputlabel">Allocate to client</div>
            <select
              className="select-css"
              value={modalSelectedUser}
              onChange={userSelectChangeHandler}
            >
              {usersList.map((user) => (
                <option value={user.id} key={user.id}>
                  {user.name}
                </option>
              ))}
            </select>

            <button
              onClick={() => {
                deleteButtonHandler(selectedTime.id);
              }}
            >
              Delete
            </button>
            <button
              onClick={() => {
                setShowModal(false);
              }}
            >
              Close
            </button>
          </div>
        )
      }
    </>

  )
}

export default AdminPage;
