import React from 'react';

import './Table.css';

export const Table = ({
  data,
  headers,
  totalResults,
  pageNum,
  pageSize,
  changePageNum
}) => {

  const numOfPages = Math.ceil(totalResults / pageSize);

  const getValueFromArray = (row, arrayOfValues) => {
    for (const key of arrayOfValues) {
        if (row?.[key]) return row?.[key];
    }
    return ''
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            {headers.map(({ label }, i) => (
              <th key={`head ${i}`}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            <tr key={`row ${i}`}>
              {headers.map(({ label, value }, j) => (
                <td data-label={label} key={`cell ${j}`}>{Array.isArray(value) ? getValueFromArray(row, value) : row?.[value]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {numOfPages > 1 && (
        <div className="pagination-container">
          {pageNum !== 1 && (
            <div className="button" onClick={() => changePageNum(--pageNum)}>
              Previous
            </div>
          )}
          <p className="mb-0">Page {pageNum} of {numOfPages}</p>
          {pageNum !== numOfPages && (
            <div className="button" onClick={() => changePageNum(++pageNum)}>
              Next
            </div>
          )}
        </div>
      )}
    </>
  )
}